import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
    meta: {
      title: 'Doctori'
    }
  },
  {
    path: '/tarificacion/:id',
    name: 'tarification',
    component: () => import('../views/TarificationView.vue'),
    meta: {
      title: 'Tarificación Doctori'
    }
  },
  {
    path: '/contratacion/:id',
    name: 'contract',
    component: () => import('../views/Contract/ContractView.vue'),
    meta: {
      title: 'Contratación Doctori'
    }
  },
  {
    path: '/solicitud/:id',
    name: 'solicitud',
    component: () => import('../views/ContractView.vue'),
    meta: {
      title: 'Solicitud de contratación Doctori'
    }
  },
  // Con token segun cia si es en demo, o el uuid si es cualquier otro entorno
  {
    path: '/:token',
    name: 'hometoken',
    component: () => import('../views/HomeView.vue'),
    meta: {
      title: 'Doctori'
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: () => import('../views/HomeView.vue'),
    meta: {
      title: 'Doctori'
    }
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title}`
  next()
})

export default router
